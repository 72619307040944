<template>
    <div>
        <div>
          <b-row class="d-flex justify-content-between">
            <b-col class="d-flex justify-content-start align-items-start">
              <b-nav-item :to="{name: 'operator-resource', params: { prefix: prefix }}"  style="color:rgba(0, 0, 0, 0)">
                  <feather-icon size="20"  icon="ChevronLeftIcon"/>
                  <span>{{$t('Resources')}}</span>
              </b-nav-item>
            </b-col>
          </b-row>
          <b-col class="d-flex justify-content-end align-items-end">
            <b-form-group>
                <b-form-input
                    v-model="searchTerm"
                    :placeholder=" $t('message.SearchPlaceholder')"
                    type="text"
                    class="d-inline-block"
                    @keyup="filterOrders()"
                />
            </b-form-group>
          </b-col>
          <b-col class="d-flex justify-content-end align-items-end">
              <b-form-group>
                <b-dropdown
                    no-caret
                    right
                    :text="(!isNaN(filterDays.label)) ? ($t('Operator.Period') + ' ' + filterDays.label + ' ' + $t('Operator.Days')) : ($t('Operator.Full') + ' ' + $t('Operator.Period'))"
                    variant="transparent"
                    class="chart-dropdown"
                    toggle-class="p-50"
                    size="sm"
                >
                    <b-dropdown-item
                    v-for="day in filterDaysOption"
                    :key="day.label"
                    @click="changeFilterDays(day)"
                    >
                    {{(!isNaN(day.label)) ? ($t('Operator.Period') + " " + day.label + " " + $t('Operator.Period')) : ($t('Operator.Full') + ' ' + $t('Operator.Period'))}}
                    </b-dropdown-item>
                </b-dropdown>
              </b-form-group>
          </b-col>
          <div class="demo-spacing-0">
              <b-alert variant="primary" :show="(showOverlay == false && orders.length == 0) ? true : false">
                  <div class="alert-body">
                      <span>No orders found for the resource selected.</span>
                  </div>
              </b-alert>
          </div>
          <br>
            <h1 class="text-center" v-if="orders.length > 0">
              {{ orders[0].resource }}
            </h1>
            <h5 class="text-center" v-if="orders.length > 0">
              {{ orders[0].workcenter }}
            </h5>
            <b-row v-for="order in ordersFiltered" :key="order.id">
              <b-col md="12">
              <b-button
                class="btn btn-operator"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                :style="getBackgroundColor(order.progress)"
                :to="{name: 'operator-production-progress', params: { prefix: prefix, order_id: order.preactor_order_id, resource_id: resource_id } }"
              >
                <b-table-simple style="background-color: rgba(0, 0, 0, 0.0); color: white">
                  <b-tbody>
                    <div class="text-center text-md-left d-md-flex justify-content-md-between">
                      <div>
                        <h1>{{ order.order_no }}</h1>
                        <p>{{ order.op_no }}</p>
                        <p>{{ order.part_no }}</p>
                        <p>{{ order.operation_name }}</p>
                      </div>
                      <div class="text-center text-md-right">
                        <h1>{{ order.progress }}</h1>
                        <p>Setup Start: {{ order.setup_start }}</p>
                        <p>Start Time: {{ order.start_time }}</p>
                        <p>End Time: {{ order.end_time }}</p>
                      </div>
                    </div>
                  </b-tbody>
                </b-table-simple>
              </b-button>
              </b-col>
            </b-row>
        </div>


    </div>
</template>

<script>
import { BCard, BCol, BRow, BButton, BLink, BAlert, BTableSimple, BTbody, BCardText, BNavItem, BFormGroup, BDropdown, BDropdownItem, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BLink,
    BAlert,
    BTableSimple,
    BTbody,
    BCardText,
    BNavItem,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
      orders: [],
      statusList: [],
      showOverlay: true,
      resource_id: this.$router.currentRoute.params.resource_id,

      filterDaysOption: [{label: 7, value: 7}, {label: 45, value: 45}, {label: 60, value: 60}, {label: 'All', value: 3000}],
      filterDays: {label: 7, value: 7},
      searchTerm: localStorage.getItem('searchTermOrders2') || '',
      ordersFiltered: []
    }
  },
  created(){
    this.getCurrentlyStatus()
    this.getOrders()
  },
  methods: {
    getOrders(){
      this.orders = []
      this.showOverlay = true
      $emit("atualizaShowOverlay", true);
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/order?filterDays=${this.filterDays.value}&resource=${this.resource_id}&site_guid=${this.currentSite.guid}`)
        .then((response) => { this.showOverlay = false; response.data.response.forEach(order => {
          this.orders.push(order)
        })
        this.filterOrders();
        $emit("atualizaShowOverlay", false);
      }).catch(() => this.showOverlay = false)
    },
    getCurrentlyStatus(){
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/currently-status?site_guid=${this.currentSite.guid}`)
          .then((response) => { response.data.response.forEach(status => {
              this.statusList.push({text: status.name, value: status.id, color: status.color})
          })
      })
    },
    filterOrders(){

      if (this.searchTerm != '')
          return this.ordersFiltered = this.orders.filter(el => (el.order_no.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1))
      return this.ordersFiltered = this.orders
    },
    getBackgroundColor(status){
      const color = this.statusList.find(el => (el.text == status))
      if (color)
          return `background-color:${color.color} !important;`
      return `background-color:secondary`
    },
    changeFilterDays(day){
      this.filterDays = day
      this.getOrders()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
